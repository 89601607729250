import React from "react";

const CodeImg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 96 960 960"
      width="100%"
    >
      <path d="M520 936v-80h80v80h-80Zm-80-80V656h80v200h-80Zm320-120V576h80v160h-80Zm-80-160v-80h80v80h-80Zm-480 80v-80h80v80h-80Zm-80-80v-80h80v80h-80Zm360-280v-80h80v80h-80ZM170 406h140V266H170v140Zm-50 50V216h240v240H120Zm50 430h140V746H170v140Zm-50 50V696h240v240H120Zm530-530h140V266H650v140Zm-50 50V216h240v240H600Zm80 480V816h-80v-80h160v120h80v80H680ZM520 656v-80h160v80H520Zm-160 0v-80h-80v-80h240v80h-80v80h-80Zm40-200V296h80v80h80v80H400Zm-190-90v-60h60v60h-60Zm0 480v-60h60v60h-60Zm480-480v-60h60v60h-60Z" />
    </svg>
  );
};

export default CodeImg;
