import React from "react";

const RotateLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M21.75 44q-2.4-.35-4.65-1.25-2.25-.9-4.25-2.4l2.15-2.2q1.6 1.2 3.3 1.875 1.7.675 3.45.975Zm4.5 0v-3q5.5-1.05 9.125-5.175T39 25.85q0-6.35-4.325-10.675Q30.35 10.85 24 10.85h-1l3.95 3.95-2.2 2.2-7.65-7.65 7.65-7.65 2.2 2.2L23 7.85h1q3.75 0 7.025 1.4 3.275 1.4 5.725 3.85 2.45 2.45 3.85 5.725Q42 22.1 42 25.85q0 7-4.45 12.05-4.45 5.05-11.3 6.1ZM9.7 37.2q-1.4-1.9-2.325-4.225Q6.45 30.65 6.1 28.1h3.05q.25 1.9.925 3.65T11.9 35ZM6.1 23.6q.35-2.5 1.25-4.775.9-2.275 2.35-4.275l2.2 2.15q-1.15 1.65-1.825 3.4t-.925 3.5Z" />
    </svg>
  );
};

export default RotateLeft;
