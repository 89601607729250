import React from "react";

const Center = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M44 21H21V44H44V21Z" strokeWidth="2" />
    </svg>
  );
};

export default Center;
