import React from "react";

const WA = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14_2)">
        <path
          d="M11.575 62V58.275H4L8.725 51.425H6.35L13 42L16 46.25L19 42L25.65 51.425H23.3L28 58.275H20.45V62H17.575V58.275H14.45V62H11.575ZM21 56.775H25.125L20.4 49.925H22.625L19 44.775L17 47.65L19.65 51.425H17.3L21 56.775ZM6.9 56.775H19.125L14.4 49.925H16.625L13 44.775L9.375 49.925H11.625L6.9 56.775ZM6.9 56.775H11.625H9.375H16.625H14.4H19.125H6.9ZM21 56.775H17.3H19.65H17H22.625H20.4H25.125H21Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip1_14_2)">
        <path
          d="M81.6281 43.5V40.7063H75L79.1344 35.5688H77.0562L82.875 28.5L85.5 31.6875L88.125 28.5L93.9438 35.5688H91.8875L96 40.7063H89.3938V43.5H86.8781V40.7063H84.1438V43.5H81.6281ZM89.875 39.5813H93.4844L89.35 34.4438H91.2969L88.125 30.5813L86.375 32.7375L88.6937 35.5688H86.6375L89.875 39.5813ZM77.5375 39.5813H88.2344L84.1 34.4438H86.0469L82.875 30.5813L79.7031 34.4438H81.6719L77.5375 39.5813ZM77.5375 39.5813H81.6719H79.7031H86.0469H84.1H88.2344H77.5375ZM89.875 39.5813H86.6375H88.6937H86.375H91.2969H89.35H93.4844H89.875Z"
          fill="black"
        />
      </g>
      <path
        d="M19.2889 91H16.7333V87.6475H10L14.2 81.4825H12.0889L18 73L23.9111 81.4825H21.8222L26 87.6475H19.2889V91ZM12.5778 86.2975H16.7778H14.7778H21.2222H19.2444H23.4444H12.5778ZM12.5778 86.2975H23.4444L19.2444 80.1325H21.2222L18 75.4975L14.7778 80.1325H16.7778L12.5778 86.2975Z"
        fill="black"
      />
      <path
        d="M71.2889 64H68.7333V60.6475H62L66.2 54.4825H64.0889L70 46L75.9111 54.4825H73.8222L78 60.6475H71.2889V64ZM64.5778 59.2975H68.7778H66.7778H73.2222H71.2444H75.4444H64.5778ZM64.5778 59.2975H75.4444L71.2444 53.1325H73.2222L70 48.4975L66.7778 53.1325H68.7778L64.5778 59.2975Z"
        fill="black"
      />
      <path
        d="M22.7722 32H19.2583V27.3438H10L15.775 18.7812H12.8722L21 7L29.1278 18.7812H26.2556L32 27.3438H22.7722V32ZM13.5444 25.4688H19.3194H16.5694H25.4306H22.7111H28.4861H13.5444ZM13.5444 25.4688H28.4861L22.7111 16.9062H25.4306L21 10.4688L16.5694 16.9062H19.3194L13.5444 25.4688Z"
        fill="black"
      />
      <g clipPath="url(#clip2_14_2)">
        <path
          d="M40.6813 98.5V95.7063H35L38.5438 90.5688H36.7625L41.75 83.5L44 86.6875L46.25 83.5L51.2375 90.5688H49.475L53 95.7063H47.3375V98.5H45.1813V95.7063H42.8375V98.5H40.6813ZM47.75 94.5813H50.8438L47.3 89.4438H48.9688L46.25 85.5813L44.75 87.7375L46.7375 90.5688H44.975L47.75 94.5813ZM37.175 94.5813H46.3438L42.8 89.4438H44.4688L41.75 85.5813L39.0312 89.4438H40.7188L37.175 94.5813ZM37.175 94.5813H40.7188H39.0312H44.4688H42.8H46.3438H37.175ZM47.75 94.5813H44.975H46.7375H44.75H48.9688H47.3H50.8438H47.75Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip3_14_2)">
        <path
          d="M48.575 36V32.275H41L45.725 25.425H43.35L50 16L53 20.25L56 16L62.65 25.425H60.3L65 32.275H57.45V36H54.575V32.275H51.45V36H48.575ZM58 30.775H62.125L57.4 23.925H59.625L56 18.775L54 21.65L56.65 25.425H54.3L58 30.775ZM43.9 30.775H56.125L51.4 23.925H53.625L50 18.775L46.375 23.925H48.625L43.9 30.775ZM43.9 30.775H48.625H46.375H53.625H51.4H56.125H43.9ZM58 30.775H54.3H56.65H54H59.625H57.4H62.125H58Z"
          fill="black"
        />
      </g>
      <g clipPath="url(#clip4_14_2)">
        <path
          d="M73.575 89V85.275H66L70.725 78.425H68.35L75 69L78 73.25L81 69L87.65 78.425H85.3L90 85.275H82.45V89H79.575V85.275H76.45V89H73.575ZM83 83.775H87.125L82.4 76.925H84.625L81 71.775L79 74.65L81.65 78.425H79.3L83 83.775ZM68.9 83.775H81.125L76.4 76.925H78.625L75 71.775L71.375 76.925H73.625L68.9 83.775ZM68.9 83.775H73.625H71.375H78.625H76.4H81.125H68.9ZM83 83.775H79.3H81.65H79H84.625H82.4H87.125H83Z"
          fill="black"
        />
      </g>
      <path
        d="M37.2889 54H34.7333V50.6475H28L32.2 44.4825H30.0889L36 36L41.9111 44.4825H39.8222L44 50.6475H37.2889V54ZM30.5778 49.2975H34.7778H32.7778H39.2222H37.2444H41.4444H30.5778ZM30.5778 49.2975H41.4444L37.2444 43.1325H39.2222L36 38.4975L32.7778 43.1325H34.7778L30.5778 49.2975Z"
        fill="black"
      />
      <path
        d="M79.2889 21H76.7333V17.6475H70L74.2 11.4825H72.0889L78 3L83.9111 11.4825H81.8222L86 17.6475H79.2889V21ZM72.5778 16.2975H76.7778H74.7778H81.2222H79.2444H83.4444H72.5778ZM72.5778 16.2975H83.4444L79.2444 10.1325H81.2222L78 5.4975L74.7778 10.1325H76.7778L72.5778 16.2975Z"
        fill="black"
      />
      <path
        d="M52.7722 75H49.2583V70.53H40L45.775 62.31H42.8722L51 51L59.1278 62.31H56.2556L62 70.53H52.7722V75ZM43.5444 68.73H49.3194H46.5694H55.4306H52.7111H58.4861H43.5444ZM43.5444 68.73H58.4861L52.7111 60.51H55.4306L51 54.33L46.5694 60.51H49.3194L43.5444 68.73Z"
        fill="black"
      />
      <defs>
        <clipPath id="clip0_14_2">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(4 40)"
          />
        </clipPath>
        <clipPath id="clip1_14_2">
          <rect
            width="21"
            height="18"
            fill="white"
            transform="translate(75 27)"
          />
        </clipPath>
        <clipPath id="clip2_14_2">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(35 82)"
          />
        </clipPath>
        <clipPath id="clip3_14_2">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(41 14)"
          />
        </clipPath>
        <clipPath id="clip4_14_2">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(66 67)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WA;
