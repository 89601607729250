import React from "react";

const Flip = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M19.6 42H9q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h10.6v3H9v30h10.6Zm3 4V2h3v44ZM39 9h-.7V6h.7q1.2 0 2.1.9.9.9.9 2.1v.7h-3Zm0 17.75v-5.5h3v5.5ZM39 42h-.7v-3h.7v-.7h3v.7q0 1.2-.9 2.1-.9.9-2.1.9Zm0-23.75V12.7h3v5.55Zm0 17.05v-5.55h3v5.55ZM28.6 42v-3h6.7v3Zm0-33V6h6.7v3Z" />
    </svg>
  );
};

export default Flip;
